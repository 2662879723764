// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

// --------ElementUI 引入---------
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/specialReport.less";
// Vue.use(ELEMENT);
Vue.use(ElementUI);
// ---------------------------------
// 引入自定义的全局公共css----------
import './assets/css/common.css'
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
// 引入echarts--------------
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
import * as socketApi from '../public/static/ws'
Vue.prototype.socketApi = socketApi
//打印
import Print from 'vue-print-nb'
Vue.use(Print);  //注册

// ------------------------优化---------------------------
// let a = require('../public/static/main')

//获取全局配置参数
import { getListConfig } from "@/api/comm.js";
let config = JSON.parse(localStorage.getItem("config"));
if (!config) {
    getListConfig().then(res => {
        if (res.code == 400200) {
            document.title = res.data.systematic_name;
            localStorage.setItem("config", JSON.stringify(res.data));
        }
    });
} else {
    document.title = config.systematic_name;
}

// process.env.VUE_APP_portUrl = "https://tpct.cqsqy.com"
// Vue.prototype.VUE_APP_QQ = "https://tpct.cqsqy.com"
// process.env.VUE_APP_imgCnd = "https://res.cqsqy.com/img"











// ----------------发布时取反--------------------
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.prototype.$log = console.log;
/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')